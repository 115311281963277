import React, { useEffect, useState } from "react"
import SidebarWithLinksAndSearch from "../../common/SidebarWithLinksAndSearch"
import TableOfContents from "../../molecules/TableOfContents"
import { articles } from "../../../Content/Articles"
import IsArticleHelpful from "../../common/IsArticleHelful"
import { stripHtmlTags } from "../../../utils/utils"
import Content from "./Content"
import RecommendedArticles from "../../common/RecommendedArticles"

const ArticleContentBody = ({ slug }) => {
  const articlePost = articles.find(post => post.slug === slug) // Find the article by slug

  const [tocItems, setTocItems] = useState([])

  useEffect(() => {
    if (articlePost && articlePost.title) {
      const headerTitle = stripHtmlTags(articlePost.title)
      const tocItems = [
        headerTitle,
        ...articlePost.contentSections
          .filter(section => section.title) // Filter out sections without a title
          .map(section => section.title),
      ]
      setTocItems(tocItems)
    }
  }, [articlePost, setTocItems])

  if (!articlePost) {
    return (
      <div className="synkli-section--horizontal-space synkli-section--vertical-space">
        Article not found.
      </div>
    )
  }

  return (
    <div className="synkli-section--horizontal-space synkli-section--vertical-space flex flex-col-reverse lg:flex-row gap-10">
      <div className="w-full lg:w-[25%] hidden md:block">
        <SidebarWithLinksAndSearch />
      </div>
      <div className="w-full lg:w-[75%]">
        <div className="flex flex-col-reverse lg:flex-row gap-10">
          <div className="w-full lg:w-[65%]">
            <Content selectedArticle={articlePost} />
          </div>
          <div className="w-full lg:w-[35%]">
            <TableOfContents items={tocItems} />
          </div>
        </div>

        <div className="mt-20">
          <h2 className="text-[28px] leading-[1.2em] font-[600] text-[#000000] mb-6">
            All Articles
          </h2>

          <RecommendedArticles />
        </div>

        <IsArticleHelpful />
      </div>
    </div>
  )
}

export default ArticleContentBody
