import React from "react";
import ContentSection from "../../molecules/ContentSection";
import InnerPageHeader from "../../molecules/InnerPageHeader";

const Content = ({ selectedArticle }) => {



  if (!selectedArticle) {
    return <div className="synkli-section--horizontal-space ">No Article found!</div>; // or handle this appropriately
  }

  return (
    <div>
      <InnerPageHeader
        title={selectedArticle.title}
        description={selectedArticle.description}
      />

      {selectedArticle.contentSections.map((section, index) => (
        <ContentSection
          key={index}
          title={section.title}
          description={section.description}
          list={section.list}
          image={section.image}
          imageEC={section.imageEC}
        />
      ))}
    </div>
  );
};

export default Content;
