import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"
import { articles } from "../../Content/Articles"
import { stripHtmlTags } from "../../utils/utils"
import { articlesPostTypeSlug } from "../../Content/Global"

const RecommendedArticles = ({ searchQuery }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const articlesPerPage = 10 // Number of articles per page

  // Filter the articles based on the search query
  const filteredArticles = searchQuery
    ? articles.filter(article =>
        stripHtmlTags(article.title)
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    : articles

  // Calculate total pages
  const totalPages = Math.ceil(filteredArticles.length / articlesPerPage)

  // Get articles for the current page
  const indexOfLastArticle = currentPage * articlesPerPage
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage
  const currentArticles = filteredArticles.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  )

  // Function to handle page changes
  const handlePageChange = page => {
    setCurrentPage(page)
  }

  return (
    <div>
      {currentArticles.length === 0 ? (
        <p className="text-[18px] text-[#666666] py-4">
          No related articles found.
        </p>
      ) : (
        currentArticles.map((article, index) => (
          <Link
            key={article.slug}
            to={articlesPostTypeSlug + article.slug + "/"}
            className={`text-[18px] md:text-[20px] leading-[1.4em] text-[#202020] py-4 px-3 flex gap-4 justify-between items-center border-t ${
              index === currentArticles.length - 1 ? "border-b" : ""
            }`}
          >
            <span>{stripHtmlTags(article.title)}</span>
            <FontAwesomeIcon
              icon={faArrowRight}
              className="transform rotate-[320deg] text-[#B695F8] text-[28px] md:text-[40px]"
            />
          </Link>
        ))
      )}

      {/* Pagination Controls */}
      <div className="flex flex-wrap justify-center mt-16 lg:mt-28">
        <button
          type="button"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`mx-1 my-1 px-3 py-1 sm:px-4 sm:py-3 border border-[#BBBBBB] rounded ${
            currentPage === 1 ? "text-[#979797]" : "text-[#000000]"
          } transition hover:bg-[#B695F8] hover:text-[#F2F1FA]`}
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            type="button"
            onClick={() => handlePageChange(index + 1)}
            className={`mx-1 my-1 px-3 py-1 sm:px-5 sm:py-3 border border-[#BBBBBB] rounded ${
              currentPage === index + 1
                ? "bg-[#B695F8] text-[#F2F1FA]"
                : "text-[#000000]"
            } transition hover:bg-[#B695F8] hover:text-[#F2F1FA]`}
          >
            {index + 1}
          </button>
        ))}
        <button
          type="button"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`mx-1 my-1 px-3 py-1 sm:px-4 sm:py-3 border border-[#BBBBBB] rounded ${
            currentPage === totalPages ? "text-[#979797]" : "text-[#000000]"
          } transition hover:bg-[#B695F8] hover:text-[#F2F1FA]`}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default RecommendedArticles
